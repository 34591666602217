<template>
  <v-app style="background: hsl(36, 44%, 97%)!important">
    <v-main>

      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: "App",
  created() {
    if (document.body.clientWidth > 2000) {
      document.body.style.zoom="120%"
    } 
    
  },
  computed:{
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
  data: () => ({

  }),

};
</script>

<style>

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
:root {
  --background-color: hsl(36, 44%, 96%);
  --secondary-background-color: #4a3f35;
  --secondary-color: #fa7d09;
  --primary-color: rgb(195, 157, 99);
  --products-width: 600px;
  --danger-color: rgb(255, 22, 12);
  --semi-primary-color: rgb(195, 157, 99, 0.3);
  --quarter-primary-color: rgb(195, 157, 99, 0.75);
  --extra-margin: 15px;
}

html {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */


}
body {
  background: var(--background-color)
}

* {
  font-family: "Open Sans", sans-serif;
  scroll-behavior: smooth;
  touch-action: manipulation!important;
}
::-webkit-scrollbar {
  background-color: white;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
::-webkit-scrollbar-track {
  background: var(--background-color);
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 25px;
  border: 6px solid transparent;
  background-clip: content-box;
  
}

.v-btn {
  text-transform:none !important;
  touch-action: manipulation!important;
}


</style>
