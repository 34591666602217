<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      max-width="600px"
      @click:outside="changeDialog(false)"
    >
      <v-card id="dialog" v-if="page == 0">
        <simplebar data-simplebar-auto-hide="false" id="simplebar-scroll">
        <v-card-text>
          <v-list
            subheader
            three-line
            max-width="var(--products-width)"
            id="list"
          >
            <v-subheader id="dialogTitle">Tellimus</v-subheader>


            <div v-for="product in Object.keys(cart)" :key="product">
              <div v-for="(cartItem, index) in cart[product]" :key="index">
                <v-list-item id="list-item">
                  <v-list-item-content>
                    <div id="titleWrap">
                      <div id="t-l">
                      <v-list-item-title id="title" class="text-wrap"                        ><span id="count">{{ cartItem.amount }}x </span
                        >{{ getProductData(product).name }} <span id="productOptionTitle" v-if="cartItem.option"> - {{cartItem.option}}</span>
                        
                        </v-list-item-title>
                  </div>
                    <div id="t-r">
                      <div id="sideWrap">
                    
                         <v-btn icon @click="removeCart(product,index)" id="iconBtn">
                            <v-icon id="dicon">
                              mdi-close
                            </v-icon>
                        </v-btn>
                        </div>
                    </div>
                    </div>
          
                    <p
                      id="extras"
                      v-for="extra in cartItem.extras"
                      :key="extra"
                    >
                      {{ extra }} ({{ getProductData(product).extras[extra] }}€)
                    </p>
                    <p id="note">{{ cartItem.note }}</p>
                    <p id="price">{{ getCartItemTotal(cartItem, product) }}€</p>
                  </v-list-item-content>
    
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </div>
          </v-list>
          <v-btn id="addBtn" plain @click="changeDialog(false)">
            <v-icon id="addIcon" @click="removeCart(cartItem)"> mdi-plus-circle </v-icon>
            Lisa rohkem
          </v-btn>
          <v-divider></v-divider>

          <div id="noteWrap">
            <v-text-field
              label="Märkmed"
              :value="notes"
              @change="changeNote"
              id="noteInput"
              hint="Pilt tordile peale, nimi jne"
            ></v-text-field>
          </div>
        </v-card-text>
      </simplebar>
      <v-spacer class="space"></v-spacer>
        <v-card-actions id="actions">
          <v-spacer></v-spacer>
                    <v-btn
            color="var(--quarter-primary-color)"
            id="actionBtn"
            text
            @click="changeDialog(false)"
          >
            Sulge
          </v-btn>
          <v-btn color="var(--primary-color)" id="actionBtn" @click="page = 1">
            Tellima
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card id="dialog" v-if="page == 1">
        <simplebar data-simplebar-auto-hide="false" id="simplebar-scroll">
      <v-card-text>
        <v-subheader id="dialogTitleNL" >Tellimine</v-subheader>
        <v-container>
          <v-form ref="checkoutForm">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  outlined
                  label="Eesnimi*"
                  width="100%"
                  required
                  v-model="firstName"
                  :rules="[() => !!firstName || 'See väli on nõutud']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                outlined
                  label="Perekonnanimi*"
                  required
                  v-model="secondName"
                  :rules="[() => !!secondName || 'See väli on nõutud']"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" md="12">
                <v-text-field
                outlined
                  label="Email*"
                  :rules="emailRules"
                  required
                  v-model="email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                outlined
                  label="Telefoninumber*"
                  required
                  v-model="phone"
                  :rules="[() => !!phone || 'See väli on nõutud']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select
                outlined
                  :items="payments"
                  label="Makseviis*"
                  required
                  v-model="payment"
                  :rules="[() => !!payment || 'See väli on nõutud']"
                ></v-select>
              </v-col>
              
              <v-col cols="12" md="12">
                <v-divider class="divider"></v-divider>
                <div class="delivery-select">
                  <div :class="{'delivery-type': true, 'delivery-s': transport == 1}" @click="transport = 1"> 
                    <v-icon large>mdi-moped-outline</v-icon>
                    <span>Kohaletoomine</span>
                  </div>
                  <div :class="{'delivery-type': true, 'delivery-s': transport == 0}" @click="transport = 0"> 
                    <v-icon large>mdi-walk</v-icon>
                    <span>Järeletulemine</span>
                  </div>
                </div>
              </v-col>
<!--               <v-col cols="12" md="4">
                <v-select
                  :items="transports"
                  label="Transport*"
                  required
                  v-model="transport"
                  :rules="[() => !!transport || 'See väli on nõutud']"
                ></v-select>
              </v-col> -->
              <v-col cols="12" v-if="transport == 1">
              <ChooseAddress
            :currentPlace.sync="currentPlace"
            @setPlace="setPlace"
          />
              </v-col>
              <v-col cols="12" md="12">
                <v-divider class="divider2"></v-divider>
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="picker"
                    label="Kuupäev"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    outlined
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="picker"
                  :first-day-of-week="1"
                  :min="minDate"
                  no-title
                  locale="et"
                  @input="menu = false"

                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="menu = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  :items="timeItems"
                  v-model="time"
                  label="Aeg"
                  outlined
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-container>


          <div id="sum-box">
          <div id="sums">
            <div id="sum-pairs">
              <p class="sum">
                Vahesumma
              </p>
              <p class="sum-end">
                {{getTotal().toFixed(2)}}€
              </p>
            </div>
              <div id="sum-pairs">
              <p class="sum">
                Kohaletoomise tasu
              </p>
              <p class="sum-end">
                {{getDeliveryCost().toFixed(2)}}€
              </p>
            </div>
                  <div id="sum-pairs">
              <p class="sum b">
                Kokku
              </p>
              <p class="sum-end">
                {{(Math.round((getTotal() + getDeliveryCost()) * 100) / 100).toFixed(2)}}€
              </p>
            </div>
          </div>
          </div>
        </v-card-text>
      </simplebar>
      
      <v-spacer class="space"></v-spacer>
        <v-card-actions id="actions">
          <v-spacer></v-spacer>
          <v-btn
            color="var(--quarter-primary-color)"
            id="actionBtn"
            text
            @click="page = 0"
          >
            Tagasi
          </v-btn>
          <v-btn :color="!closed ? 'var(--primary-color)' : 'var(--danger-color)'" id="actionBtn" :loading="orderLoading" @click="makeOrder"> Kinnita Tellimus </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ChooseAddress from "./Extras/ChooseAddress.vue";
import CloseBtn from "./Extras/CloseBtn.vue";
import { gmapApi } from "vue2-google-maps";
import axios from "axios";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';

export default {
  name: "CartCheckoutVue",
  components: {
    ChooseAddress,
    CloseBtn,
    simplebar
  },
  props: {
    dialog: Boolean,
    cart: Object,
    getProductData: Function,
    note: String,
    currentPlace: Object,
    deliveryDistance: Number,
    rates: Object,
    getTotal: Function,
    closed: Boolean,
  },
  data: function () {
    return {
      menu: false,
      dOptionHover: 0,
      page: 0,
      orderLoading: false,
      firstName: "",
      secondName: "",
      phone: "",
      notes: "",
      email: "",
      transport: 1,
      payment: "",
      payments: ["Maksan kohe (Pangalink, kaart)","Maksan sularahas"],
      emailRules: [ v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'See väli on nõutud' ],
      picker: (new Date( (new Date()).setDate((new Date()).getDate() + 2) ).toISOString().substring(10, 0)),
      minDate: (new Date( (new Date()).setDate((new Date()).getDate() + 2) ).toISOString().substring(10, 0)),
      timeItems: ["8:30", "9:00", "9:30", "10:00", "10:30", "11:00", "11:30", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00", "18:30", "19:00"],
      time: "8:30"
    };
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    makeOrder() {
      if (this.closed) {
        return;
      }
      if (!this.$refs.checkoutForm.validate()) {
        
          return;
      } else {
        if (this.transport == 1) {
            if (this.currentPlace == "") {
              return;
            } 
          }
        
      }
      
      let transport_type = this.transport
      let payment_type = !this.payments.indexOf(this.payment)

      if (transport_type == 1) {
        if (Object.keys(this.currentPlace) == 0) {
          return;
        }
      }
      localStorage.setItem("cart", JSON.stringify(this.cart))

      this.orderLoading = true;
      
      axios.post(process.env.VUE_APP_API + "order",{
            first_name: this.firstName,
            last_name: this.secondName,
            email: this.email,
            phone_number: this.phone,
            payment_type: payment_type,
            transport_type: transport_type,
            address: this.currentPlace.formatted_address,
            cart: this.cart,
            note: this.notes,
            date: this.picker,
            time: this.time
      })
            .then(response => {
                if (payment_type == 1) {
                  window.location.href = response.data.payment_url
                  
                } else {
                  this.$router.push({ name: 'OrderView', params: { order_id: response.data.order_id}})
                }
                 
            })
            .catch(error => {
              this.orderLoading = false;
              alert("Tellimuse tegemisel tekkis probleem. Proovi uuesti mõne minuti pärast.")
            })
            
      },
    checkout() {
      this.$refs.checkoutForm.validate()
    },
    removeCart(id, index) {
      this.$emit("removeCart", id, index)
    },
    getDeliveryCost() {
      if (this.transport == 0) return 0.00
      const freeDelivery = this.rates.delivery.free;
      const costPerKm = this.rates.delivery.costPerKm;
      const deliveryDistance = this.deliveryDistance;
      if (this.transport == 1) {
        var deliveryCost = 3;
      } else {
        var deliveryCost = 0;
      }
      

      if (deliveryDistance > freeDelivery) {
        const paidDistance = deliveryDistance - freeDelivery;

        deliveryCost += (paidDistance / 1000) * costPerKm;
      }

      if (this.getTotal() < 10 && deliveryCost < (10 - this.getTotal()) && this.transport == 1) {
        return Math.round((10 - this.getTotal()) * 10) / 10;
      } else {
      return (Math.round(deliveryCost * 10) / 10)}
    },
    updateDistanceTo(dest) {
      var destination = dest.formatted_address;
      var origin = "Rohuaia 13, Rakvere";
      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: "DRIVING",
        },
        this.callback
      );
    },
    callback(response, status) {
      this.$emit(
        "changeDeliveryDistance",
        response.rows[0].elements[0].distance.value
      );
    },
    changeNote(note) {
      this.notes = note
    },
    setPlace(place) {
      this.$emit("setPlace", place);
      this.updateDistanceTo(place);

    },
    changeDialog(bool) {
      this.$emit("changeDialog", bool);
    },
    getCartCount(id) {
      if (id in this.cart) {
        let count = 0;
        for (let i = 0; i < this.cart[id].length; i++) {
          count += this.cart[id][i].amount;
        }

        return count;
      } else {
        return 0;
      }
    },
    getCartItemTotal(currentCartItem, productId) {
      let totalPrice = 0;
      let currentTotalPrice = 0.0;
      let currentProduct = this.getProductData(productId);

      if (currentCartItem.option == undefined) {
        currentTotalPrice += currentProduct.price;
      } else {
        currentTotalPrice += currentProduct.options[currentCartItem.option];
      }
      for (let ie = 0; ie < currentCartItem.extras.length; ie++) {
        currentTotalPrice += currentProduct.extras[currentCartItem.extras[ie]];
      }

      currentTotalPrice = currentTotalPrice * currentCartItem.amount;
      totalPrice += currentTotalPrice;
      return (Math.round(totalPrice * 100) / 100).toFixed(2);
    },
    getExtras(cartItem) {
      let extras = "";
      for (let i = 0; i < cartItem.extras.length; i++) {
        extras += cartItem.extras[i];
        if (i < cartItem.extras.length - 1) {
          extras += ", ";
        }
      }
      return extras;
    },
  },
};
</script>

<style scoped>
.divider {
  padding-top: 18px;
  padding-bottom: 10px;
}
.divider2 {
  margin-top: 20px;
  margin-bottom: 26px;
}
.space {
  height: 58px;
}
#simplebar-scroll {
  max-height: 500px;
}
#dialog {
  height: 100%;


}
.delivery-select {
  width: 98%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}
.delivery-type {
  opacity: 0.5;
  border: 2px solid rgb(177, 177, 177);
  width: 49%;
  height: 110px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}

.delivery-type span { 
  text-align: center;
  font-weight: bold;
}
.delivery-type i {
  font-size: 60px!important;
}
.delivery-type:nth-child(2) i {
  padding-top: 10px;
  font-size: 50px!important;
}

.delivery-type:hover {
  opacity: 0.7;
  /* box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important */

}
.v-text-field--outlined fieldset {
  border: 2px solid;
}
.col-md-6, .col-sm-6, .col-12 {
  padding: 6px!important;
  padding-bottom: 0px!important;
}
.delivery-type.delivery-s {
  opacity: 1;
}
#productOptionTitle {
  font-weight: 400;
}
@media (min-width: 700px) {
  #sum-box {
  width: 50%;
}
}
#titleWrap {
  margin-bottom: -2px;
}
#t-l {
  float: left;
  width: 70%;
}
#t-r {
  float: right;
  width: 29%;
  margin-top: -4px;
  
}
#sideWrap {
  display: flex;
  float:right;

}

#dicon {

  font-size: 24px;
  cursor: pointer;
  margin-bottom: 5px;
}

#dialog {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}
.sum {
  font-size: 17px;
  color: black;
  font-weight: 400;
  margin-right: auto;
}
.b {
  font-weight: 600;
}
#sums {
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  gap: 1px;

}
#sum-pairs {
  display:flex;
  justify-content: flex-end;
}
.sum-end{
  text-align: right;
    color: var(--primary-color);
  font-size: 17px;
  font-weight: 400;
}
#noteWrap {
  margin-left: 5px;
  border: none;
}
#noteWrap #avatar {
  top: -6px;
  right: 0;
  position: absolute;
}

#addBtn {
  margin-bottom: 8px;
}
#addIcon {
  color: var(--primary-color);
}
#noteInput {
  color: rgba(0, 0, 0, 0.8);
  margin-left: 10px;
  width: 90%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#note {
  color: rgba(0, 0, 0, 0.7);
  margin-left: var(--extra-margin);
  font-weight: 500;
}
#price {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 0px;
  color: var(--primary-color);
  font-size: 16px;
  font-weight: 500;
}
#dialogTitle {
  padding: 30px 0;
  font-size: 25px;
  font-weight: 700;
  color: rgb(32, 33, 37, 0.85);
}

#dialogTitleNL {
  /*NL == No List; list has a builtin margin */
  padding: 30px 0;
  font-size: 25px;
  font-weight: 700;
  color: rgb(32, 33, 37, 0.85);

}
#page-one {
  margin-right: 15px;
  margin-left: 15px;
}
#title {
  font-weight: 500;
}
@media (min-width: 340px) {
  #title {
    font-size: 16px;
  }

  #extras {
    font-size: 15px;
  }
  #productOptionTitle {
    font-size: 16px;
  }
}
#extras {
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  margin-left: var(--extra-margin);
}
.v-application p {
  margin-bottom: 4px;
}
@media (max-width: 340px) {
  #title {
    font-size: 13px;
  }

  #extras {
    font-size: 11px;
  }
   #productOptionTitle {
    font-size: 11px;
  }
}
#extras {
  margin-top: -4px;
}
#actions {
  margin-top: 0;
  padding-top: 0;
  background: white;
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 999;
}

#actionBtn {
  text-transform: none;
  color: white;
  font-weight: 550;
  height: 50px;
  min-width: 120px;
  font-size: 15px;
  margin: 0 0px;
}
</style>
